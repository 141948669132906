import React from "react"
import { Link } from "gatsby"

class NavbarMenu extends React.Component {
  render() {
    function toggleMoreInfo() {
      document.querySelector('#more_info').classList.toggle('is-active');
    }
    function toggleConstactUs() {
      document.querySelector('#contact_us').classList.toggle('is-active');
    }
    return (
      < >
        <Link className="navbar-item" to="/">
          Home
        </Link>
        <Link className="navbar-item" to="/documents">
          Documents
        </Link>
        <Link className="navbar-item" to="/bill-payment">
          Billing
        </Link>
        <Link className="navbar-item" to="/en/election-information">Election Info</Link>
        <Link className="navbar-item" to="/alerts">
          Alerts
        </Link>
        <div id="more_info" className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link" onClick={toggleMoreInfo}>More Info</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/smart-meter">Smart Meter</Link>
            <Link className="navbar-item" to="/waste-collection">Waste Collection</Link>
            <Link className="navbar-item" to="/board-of-directors">Board of Directors</Link>
            <Link className="navbar-item" to="/district-consultants"> District Consultants </Link>
            <Link className="navbar-item" to="/tax-information">Tax Information</Link>
            <Link className="navbar-item" to="/budget-information">Budget Information</Link>
            <Link className="navbar-item" to="/security">Security</Link>
            <Link className="navbar-item" to="/useful-links">Useful Links</Link>
            <Link className="navbar-item" to="/calendar">Calendar</Link>
            <Link className="navbar-item" to="/map">Map</Link>
          </div>
        </div>
        <div id="contact_us" className="navbar-item has-dropdown is-hoverable">
          <div className="navbar-link" onClick={toggleConstactUs}>Contact Us</div>
          <div className="navbar-dropdown">
            <Link className="navbar-item" to="/contact">Contact Form</Link>
            <Link className="navbar-item" to="/requests-for-public-information">Requests for Public Information</Link>
          </div>
        </div>
      </ >
    )
  }
}

export default NavbarMenu
