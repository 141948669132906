import React from 'react'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Link from 'gatsby-link';

const Footer = () => (
    <footer className="section">
    <div className="container">
      <div className="has-text-centered">
          <p>&copy; {new Date().getFullYear()} Fort&nbsp;Bend&nbsp;County&nbsp;MUD&nbsp;24 <span style={{margin: '0 1rem'}}>|</span> Website provided by <OutboundLink href="//touchstonedistrictservices.com/">Touchstone</OutboundLink>
          </p>
          <p><Link to="/legal-notices-and-disclaimers" className="button is-secondary-accent" style={{textTransform: 'uppercase'}}>Legal Notices and Disclaimers</Link></p>
      </div>
    </div>
  </footer>
)

export default Footer;